@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

body {
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
}
#no-scroll {
  overflow: hidden;
  height: 100vh;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #151515;
  background-image: url(../src/assets/bars.svg);
  background-repeat: no-repeat;
  background-position: center;
}
#preloader-none {
  opacity: 0;
}
